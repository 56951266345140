<template>
  <div id="ProjectsList--project-container" class="">
    <!-- PAGE CUSTOMIZING CONTENT AND DESIGN -->
    <div>
      <h2>BIG WARNING DON'T FORGET TO RUN THE SERVER API BACKEND!!!!!!!</h2>
      <h4>Main Pages customizing content and design</h4>
    </div>
    <div id="ProjectsList--custom-page-Links-container">
        <router-link
            v-for="(page, index) in pagesCustomDataList"
            :key="index"
            :to='page.link'
            class="">
            <h3>{{page.title}}</h3>
        </router-link>
    </div>

    <!-- PAGE OTHER CUSTOMIZING OPTIONS -->
    <div>
      <h4>Page other customizing options</h4>
    </div>
    <div>
      <router-link to="/">
          <h4>CHANGE THE RANKING OF PROJECTS</h4>
      </router-link>
    </div>
    <div>
      <router-link to="/">
          <h4>CHANGE THE TRANSITION BETWEEN PROJECTS</h4>
      </router-link>
    </div>

    <!-- SUBTITLE: LNACARLA PROJECTS LIST MANAGEMENT -->
    <div>
      <h4>LNACARLA Projects List Management</h4>
    </div>

    <!-- SEARCH FILTER INPUT -->
    <div class="ProjectsList--header-menu">

      <div class="">
        <input type="text" class="form-control" placeholder="Search by title"
          v-model="title"/>
          <button class="" type="button"
            @click="searchTitle"
          > Search
          </button>
      </div>
      <!-- Project Count -->
      <div>
         <span class="ProjectsList--Count">Nb. of project</span>
         <span class="ProjectsList--Count-plural" v-if="projectsCount > 1">s</span>:
         {{ projectsCount }}
      </div>

      <!-- ADD NEW PROJECT MODAL -->
      <div class="ProjectsList--header-menu-item">
          <!-- Add a new project -->
          <Tooltip :tooltipText="'Add a new project'">
            <RiAddFill @click="showModal = true"/>
          </Tooltip>
      </div>

      <!-- Project Modal Slot Teleport and Body -->
      <Teleport to="body">
        <modal :show="showModal" @close="showModal = false">
          <!-- Use the modal component, pass in the prop with slot component-->
          <template #header>
            <!-- Here the header slot value from the modal component -->
            <h3>Add a new project</h3>
          </template>

          <!-- Use the modal component, pass in the prop with slot component-->
          <template #body>
            <!-- Here the body slot value from the modal component -->
            <AddProject/>
          </template>
        </modal>
      </Teleport>

      <!-- REMOVE ALL PROJECTS BUTTON -->
      <div class="ProjectsList--header-menu-item">
        <!-- Remove all projects -->
        <Tooltip :tooltipText="'Delete all projects'"
                 :flow="'right'">
          <RiDeleteBin6Fill @click="removeAllProjects"/>
        </Tooltip>
      </div>
    </div>

    <!--  PROJECTS LIST -->
    <div class="ProjectsList--project-container-card">
      <div
        :class="{ active: index == currentIndex }"
        v-for="(project, index) in projectsUserSession"
        :key="index"
        @click="setActiveProject(project, index)"
      >
       <!-- FULL PROJECT CARD CONTAINER -->
       <!-- Project User for filtering the project matching the current session user (localStorage value) -->
       <div
          class="ProjectsList--project-card"
          >
          <div
              class="ProjectsList--project-card--item">
              <div>
                 <label>
                    <strong>Title:</strong>
                    {{ project.title }}
                  </label>
              </div>
              <!-- user Id -->

              <div>
                AuthorID : {{ project.userID }}
              </div>
              <div>
                ProjectID : {{ project.id }}
              </div>

              <div>
                Created at : {{ formattingDate(project.createdAt) }}
              </div>
              <div>
                Updated at : {{ formattingDate(project.updatedAt) }}
              </div>
          </div>

            <!-- PROJECT INFO HIDDEN -->
            <div class="ProjectsList--project-card--item">
                <div>Picture Stock infos</div>
                <div>Number of photos:</div>
                <div>Uploaded photos:</div>
            </div>

            <!-- PROJECT INFO HIDDEN -->
            <div class="ProjectsList--project-card--item">
              <div>
                  <label>
                    <strong>Status:</strong>
                    {{ project.published ? "Published" : "Pending" }}
                  </label>
                <div>
                  <router-link :to="'/edit-mode-projects/' + project.id" class="badge badge-warning">EDIT {{ project.title }} DETAIL PAGE</router-link>
                </div>
              </div>
            </div>
            <div class="ProjectsList--project-card--item ProjectsList--delete">
              <Tooltip :tooltipText="'Delete project'">
                <RiDeleteBin6Line @click="removeOneProject(project.id)"/>
              </Tooltip>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectDataService from "../../../services/project/ProjectDataService";
import AddProject from "./AddProject.vue";
// https://vuejs.org/examples/#modal (Options Syntax)
import Modal from '../../edit_mode/modal/AddProjectModal.vue';
import RiDeleteBin6Line from '../../../components/icons/features/delete/RiDeleteBin6Line.vue'
// import MaterialSymbolsAccountCircle from '../../../components/icons/authentication/profile/MaterialSymbolsAccountCircle.vue'
import RiAddFill from '../../../components/icons/features/add/RiAddFill.vue'
import RiDeleteBin6Fill from '../../../components/icons/features/delete/RiDeleteBin6Fill.vue'
import Tooltip from "../../../components/edit_mode/tooltip/Tooltip.vue";

export default {
  name: "projects-list",
  components: {
    Modal,
    AddProject,
    RiDeleteBin6Line,
    RiAddFill,
    RiDeleteBin6Fill,
    Tooltip
  },
  data() {
    return {
      projects: [],
      currentProject: null,
      currentIndex: -1,
      title: "",
      showModal: false,
      userInfosLocalStorage: JSON.parse(localStorage.getItem('user')),
      pagesCustomDataList: [
      {
        link: "/home-edit-mode",
        title: "HOME PAGE",
      },
      {
        link: "/about-edit-mode",
        title: "ABOUT PAGE",
      },
      {
        link: "/coming-soon-edit-mode",
        title: "COMING SOON PAGE",
      },
      {
        link: "/not-found-edit-mode",
        title: "NOT FOUND PAGE",
      },
      {
        link: "/projects-list-edit-mode",
        title: "PROJECTS LIST PAGE",
      },
    ]
    };
  },
  computed: {
    // Documentation (computed)
    // https://vuejs.org/guide/essentials/computed.html#writable-computed
    projectsCount () {
      // https://vuex.vuejs.org/guide/modules.html#namespacing
      return this.$store.getters['project/projectsCount']
    },
    projectsUserSession () {
      // https://vuex.vuejs.org/guide/modules.html#namespacing
      return this.$store.getters['project/projectsUserSession']
    }
  },
  methods: {
    retrieveProjects() {
      // We call the getAll() function from src\services\project\ProjectDataService.js
      // IMPORTANT response.data came FROM BACKEND ==> project.controller.js (RELOAD THE BACKEND SERVER WHEN MODIFYING )
      ProjectDataService.getAll()
        .then(response => {
          this.projects = response.data;
          console.log("response.data for All users in the database (retrieveProjects() ProjectsList.vue) =", response.data);

          // Update the localStorage when the database has been updated
          // Then the localStorage of the user session will update the initial State of the Vuex Store for the projects

          // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
          // https://stackoverflow.com/questions/46894352/filter-array-of-objects-based-on-another-array-in-javascript

          // Get the current id of the user session
          const user = JSON.parse(localStorage.getItem('user'));
          const id_filter = [user.id];
          console.log("id_filter =", id_filter)

          // Update object's name property with filter by user session id
          console.log("project localStorage filtered (ProjectList.vue) =", response.data.filter(project => id_filter.includes(project.userID)))

          // Here we defined the local storage
          localStorage.setItem('projects', JSON.stringify(response.data))

          console.log("localStorage.getItem('projects') ProjectList.vue) =" , localStorage.getItem('projects'))

          // Now we can play directy with the current id userssion project value localStorage in VueX

        })
        .catch(e => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveProjects();
      this.currentProject = null;
      this.currentIndex = -1;
    },

    setActiveProject(project, index) {
      this.currentProject = project;
      this.currentIndex = project ? index : -1;
    },
    removeOneProject(projectID) {
      // We call the delete() function from src\services\project\ProjectDataService.js
      ProjectDataService.delete(projectID)
        .then(response => {

          /*

           +  Where the response.data value is coming from ?

            the response.data is coming from the server api (Backend)
                  ==> \server_postgreql\app\controllers\project.controller.js
            ...

            Project.destroy({
              where: { id: id }
            })
              .then(num => {
                if (num == 1) {
                  // We will send this message to the Frontent
                  // The object data sent is important for the VueX state store
                  res.send({
                    message: "Project was deleted successfully!" (HERRRRRRRRRE)
                  });
                } else {
                  res.send({
                    message: `Cannot delete Project with id=${id}. Maybe Project was not found!`
                  });
                }
              })
              .catch(err => {
                res.status(500).send({
                  message: err.message || "Could not delete Project with id=" + id
                });
              });
          };

          */
          console.log(" response.data removeOneProjects -- (ProjectsList.vue) =", response.data);

          // ------------------- Update The VueX State - Delete Project -----------------*
          // dispatching the action 'delete' from the vueX store
          // IMPORTANT THE TYPE OF THE PARAM ==> INTEGER
          this.$store.dispatch("project/delete", projectID ).then(
              () => {
                console.log(" Delete Project VueX success")
              },
          )
          
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    removeAllProjects() {
      // We call the deleteAll() function from src\services\project\ProjectDataService.js
      ProjectDataService.deleteAll()
        .then(response => {
          /* Where the response.data value is coming from ?
            ==>
            the response.data is coming from the server api (Backend)
                  ==> \server_postgreql\app\controllers\project.controller.js
            ...
          */
          console.log("ProjectsList response.data (removeAllProjects) =", response.data);

          this.$store.dispatch("project/deleteAll").then(
              () => {
                // Display notifications
                console.log(" Delete All Projects VueX success")
              },
          )
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    searchTitle() {
      // We call the findByTitle() function from src\services\project\ProjectDataService.js
      ProjectDataService.findByTitle(this.title)
        .then(response => {
          this.projects = response.data;
          this.setActiveProject(null);
          console.log("ProjectsList response.data (searchTitle) =", response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // Documentation (Date Conversion in Different Formats)
    // https://www.tutorialsteacher.com/javascript/javascript-date

    formattingDate(serverDate) {
      let date = new Date(serverDate);
      return date.toLocaleDateString() +" at " + date.getHours() +":"+ date.getMinutes();
    }
  },
  mounted() {
    this.retrieveProjects();

    // The store state value comes from the localStorage ==> key ==> 'user' defined in \src\services\authentication\auth.service.js
    console.log("Global VueX state (ProjectList.vue)          =", this.$store.state)
    // console.log("this.$store                                  =", this.$store)
    // https://vuex.vuejs.org/guide/modules.html#namespacing
    // console.log("this.$store.getters('project/projectsCount') =", this.$store.getters['project/projectsCount'])
    console.log("this.$store.getters('project/projectsUserSession') =", this.$store.getters['project/projectsUserSession'])

  },
  // updated() {
  //   this.retrieveProjects();
  //   // The store state value comes from the localStorage ==> key ==> 'user' defined in \src\services\authentication\auth.service.js
  //   console.log("Global VueX state ProjectList =", this.$store.state)
  // }
};
</script>

<style scoped>
#ProjectsList--project-container {
  font-family: system-ui;
  text-align: left;
  max-width: 90%;
  padding-top: 2%;
  margin-left: 5%;
}

#ProjectsList--custom-page-Links-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

/* https://www.w3schools.com/cssref/pr_grid-gap.php */
.ProjectsList--header-menu {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 50px;
  width: 50vw;
}

.ProjectsList--header-menu-item {
  text-align: center;
}

.ProjectsList--header-menu-item svg {
  height: 2em;
  width: 2em;
}

.ProjectsList--header-menu-item svg:hover {
  cursor: pointer;
}

.ProjectsList--project-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .1fr;
  margin-top: 10px;
  border: 1px dotted rgb(232, 230, 230);
}

.ProjectsList--project-container-card {
  padding-bottom: 5%;
}

.ProjectsList--project-card--description {
  padding-top: 3%;
  overflow:hidden;
  width:350px;
  word-break:break-all;
}

.ProjectsList--project-card--item {
  padding: 20px 15px;
  border-left: 1px solid #e1dada;
}

.ProjectsList--delete svg path {
  size: 10em;
}
.ProjectsList--delete:hover {
  color:red;
  cursor: pointer;
}

.ProjectsList--Count,
.ProjectsList--Count-plural {
   font-weight: bold;
}

.ProjectsList--Count-plural {
  margin:0;
}

</style>
