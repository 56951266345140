
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
      d="M17 4h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5V2h10v2zM9 9v8h2V9H9zm4 0v8h2V9h-2z"></path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/ri
// https://api.iconify.design/ri:delete-bin-6-fill.svg
export default {
  name: 'RiDeleteBin6Fill'
}
</script>