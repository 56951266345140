<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="title">Title</label>
        <input
          type="text"
          class="form-control"
          id="title"
          required
          v-model="project.title"
          name="title"
        />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <input
          class="form-control"
          id="description"
          required
          v-model="project.description"
          name="description"
        />
      </div>

      <button @click="saveProject" class="btn btn-success">Create</button>
    </div>

    <div v-else>
      <h4>You submitted successfully!</h4>
      <button class="btn btn-success" @click="newProject">Add an other project </button>
    </div>
  </div>
</template>

<script>
import ProjectDataService from "../../../services/project/ProjectDataService";

export default {
  name: "add-project",
  // Data value to interact with the template content dynamically
  data() {
    return {
      project: {
        id: null,
        title: "",
        description: "",
        published: false
      },
      submitted: false,
    };
  },
  methods: {
    saveProject() {
      // We send a part of required data

      // We send all the project data trough the global variable data using the ProjectDataService for project creation
      ProjectDataService.create( {
            id: this.project.id,
            title: this.project.title,
            description: this.project.description,
            published: false,
            // We send the current session user data info to the backend
            // We need to associate the new created project with the current user session (Sequilize Association Table)
            userInfos: JSON.parse(localStorage.getItem('user'))
      })
        .then(response => {
          /*
            Where the response coming from at server api ==> \server_postgreql\app\controllers\project.controller.js
            ...

            project.setUsers([req.body.userInfos.id]).then(() => {
                // res.send(project);

                res.send(
                  // This object data will be sent to the frontend if the project is created successfully
                  // This object will be used by VueX Store
                  {
                    message: "Project registered successfully!",
                    userID: req.body.userInfos.id,
                    title: req.body.title,
                    description: req.body.description,
                    published: req.body.published ? req.body.published : false,
                  }
                );
              });
              ...
          */
          this.project.id = response.data.id;
          console.log("response (AddProject.vue)           =", response);
          console.log("response.data    (AddProject.vue)   =", response.data);
          console.log("response.data.id (AddProject.vue)   =", response.data.id);

          this.submitted = true;

          // We will send this data object to the backend
          const data = {
              id: response.data.id,
              userID: response.data.userID,
              title: response.data.title,
              description: response.data.description,
              date: response.data.date,
              location: response.data.location,
              backgroundColor: response.data.backgroundColor,
              published: response.data.published,
              sectionData: response.data.sectionData,
              createdAt: response.data.createdAt,
              updatedAt: response.data.updatedAt
          }

          // ------------------- Update The VueX State -----------------*
          // dispatching the action 'create' from the vueX store
          this.$store.dispatch("project/create", data).then(
              () => {
                console.log(" Create Project VueX success")
              },
          )
          // Refresh projects list
          this.refreshList();

        })
        .catch(e => {
          console.log(e);
        });
    },

    newProject() {
      this.submitted = false;
      this.project = {};
    },

    retrieveProjects() {
      // We call the getAll() function from src\services\project\ProjectDataService.js
      ProjectDataService.getAll()
        .then(response => {
          this.projects = response.data;
          console.log("ProjectsList response.data (retrieveProjects) =",response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // We need to refresh the project list behind with vueX
    refreshList() {
      this.retrieveProjects();
      this.currentProject = null;
      this.currentIndex = -1;
    },
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
