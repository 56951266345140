
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor"
      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"></path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/ri
// https://api.iconify.design/ri:delete-bin-6-line.svg
export default {
  name: 'RiDeleteBin6Line'
}
</script>