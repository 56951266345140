
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path>
  </svg>
</template>

<script>
// https://icones.js.org/collection/ri
// https://api.iconify.design/ri:add-fill.svg
export default {
  name: 'RiAddFill'
}
</script>