// WHERE ARE WE ? ==> FRONTEND
// https://stackov../../http-commonstions/47477594/how-to-use-2-instances-of-axios-with-different-baseurl-in-the-same-app-vue-js
import http from "../../http-common";

// The ProjectDataService is used in those vue files to update delete add
// And communicate via the api with the database
/*
    - Project.vue
    - ProjectList.vue
    - AddProject.vue
*/

// Vuex state for the user projects will be updated via The ProjectDataService

class ProjectDataService {
  getAll() {
    // When we call ProjectDataService.getAll() we make a http get request from the API
    /*
      Where does it come from? ==> server_postgreql\app\routes\project.routes.js
      // Retrieve all Projects
      router.get("/", projects.findAll);

    */
    return  http.get("/edit-mode-projects");
  }

  get(id) {
    // We need to update the Vuex state of the user project inside the Store

    // We also need to update the value 'user' from the localStorage:
    // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
    // https://developer.mozilla.org/fr/docs/Web/API/Window/localStorage

    // Here the returned value will send a http GET request to the backend server api

    return http.get(`/edit-mode-projects/${id}`);
  }

  create(data) {
    // We need to update the Vuex state of the user project inside the Store

    // We also need to update the value 'user' from the localStorage:
    // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
    // https://developer.mozilla.org/fr/docs/Web/API/Window/localStorage

    // Here the returned value will send a http POST request to the backend server api
    return http.post("/edit-mode-projects", data);
  }

  update(id, data) {
    // We need to update the Vuex state of the user project inside the Store

    // We also need to update the value 'user' from the localStorage:
    // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
    // https://developer.mozilla.org/fr/docs/Web/API/Window/localStorage

    //   for (let object in JSON.parse(localStorage.getItem('user'))) {
    //     if (object.id === id) {
    //         object.projects = data;
    //     }
    // }
    //   localStorage.setItem('user', JSON.stringify(data));
    //   console.log("data =", data)

    // Here the returned value will send a http PUT request to the backend server api
    return http.put(`/edit-mode-projects/${id}`, data);
  }

  delete(id) {
    // We need to update the Vuex state of the user project inside the Store

    // We also need to update the value 'user' from the localStorage:
    // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
    // https://developer.mozilla.org/fr/docs/Web/API/Window/localStorage

    // Here the returned value will send a http DELETE request to the backend server api
    return http.delete(`/edit-mode-projects/${id}`);
  }

  deleteAll() {
    // We need to update the Vuex state of the user project inside the Store

    // We also need to update the value 'user' from the localStorage
    // https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/

    // Here the returned value will send a http DELETE request to the backend server api
    return http.delete(`/edit-mode-projects`);
  }

  findByTitle(title) {
    // Here the returned value will send a http GET request to the backend server api
    return http.get(`/edit-mode-projects?title=${title}`);
  }
}

export default new ProjectDataService();
